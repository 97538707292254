import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { InventoryPrintComponent } from "../pages";
import "../assets/styles/global.style.scss";
import { PosterComponent } from "../pages/poster/poster.component";
import { CataloguePrintComponent } from "../pages/catalogue-print";

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/inventory/:categoryId"
          element={<InventoryPrintComponent />}
        />
        <Route
          path="/catalogue/:matchingId"
          element={<CataloguePrintComponent />}
        />
        <Route path="/poster/:productId" element={<PosterComponent />} />
      </Routes>
    </Router>
  );
};
