import React, { useEffect, useRef, useState } from "react";
import "./catalogue.style.scss";
import { Loader } from "../../lib/ui/components";
import { axios } from "../../lib/constants/api";
import { useParams } from "react-router-dom";
import _ from "lodash";

const allowedMeta = [
  "post_title",
  "machine_type",
  "company",
  "model",
  "year_of_manufacturing",
  "made_in",
  "controller",
  "controller_model",
  "chip_conveyor",
  "turret_type",
  "number_of_turret_tools",
  "turret_engine_type",
  "tailstock",
  "lunette",
  "chuck_size",
  "machining_diameter",
  "turning_diameter",
  "machining_length",
  "have_c_axis",
  "have_y_axis",
  "spindle_bore_type",
  "spindle_bore_diameter",
  "spindle_type",
  "max_spindle_speed",
  "x-axis-travel",
  "y-axis-travel",
  "z-axis-travel",
  "w-axis-travel",
  "table_dimensions",
  "number_of_axes",
  "tool_changer_type",
  "number_of_tools",
  "tool_std",
  "tool_size",
  "axes_mechanism",
  "supplementary_details",
];
const metaLabelMap = {
  post_title: "نام دستگاه",
  company: "شرکت سازنده",
  machine_type: "تیپ",
  model: "مدل",
  year_of_manufacturing: "سال ساخت",
  made_in: "کشور سازنده",
  controller: "کنترلر",
  controller_model: "مدل کنترلر",
  chip_conveyor: "سفاله کش",
  turret_type: "نوع تارت",
  number_of_turret_tools: "تعداد ابزار تارت",
  turret_engine_type: "نوع موتور تارت",
  tailstock: "مرغک",
  lunette: "لینت",
  chuck_size: "قطر سه نظام (میلی متر)",
  machining_diameter: "قطر ماشین کاری (میلی متر)",
  turning_diameter: "قطر قابل گردش (میلی متر)",
  machining_length: "طول قابل تراشکاری (میلی متر)",
  have_c_axis: "محور c",
  have_y_axis: "محور y",
  spindle_bore_type: "گلو اسپیندل",
  spindle_bore_diameter: "قطر گلویی (میلی متر)",
  spindle_type: "نوع اسپیندل",
  max_spindle_speed: "حداکثر دور اسپیندل (RPM)",
  table_dimensions: "ابعاد میز (میلی متر)",
  number_of_axes: "تعداد محور",
  tool_changer_type: "نوع تول چنجر",
  number_of_tools: "تعداد ابزار",
  tool_std: "استاندارد ابزار",
  tool_size: "اندازه ابزار",
  axes_mechanism: "مکانیزم محوره",
  "x-axis-travel": "کورس محور X (میلی متر)",
  "y-axis-travel": "کورس محور Y (میلی متر)",
  "z-axis-travel": "کورس محور Z (میلی متر)",
  "w-axis-travel": "کورس محور W (میلی متر)",
  supplementary_details: "توضیحات تکمیلی",
};

export const CataloguePrintComponent = () => {
  const { matchingId } = useParams();
  const [products, setProducts] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const printContentRef = useRef(null);

  useEffect(() => {
    // Add the class when the component mounts
    document.body.classList.add("catalogue");
  }, []);

  useEffect(() => {
    const isSinglePrintPage = products.length === 1;

    if (isSinglePrintPage) {
      document.body.style.backgroundSize = "cover";
    } else {
      document.body.style.backgroundSize = "contain";
    }
  }, [products]);

  useEffect(() => {
    const handleFetchProducts = async () => {
      const productsRequest = await axios.get(`/explore/${matchingId}`);
      const fetchedProducts = productsRequest.data.products;
      setIsLoaderVisible(false);
      setProducts(fetchedProducts);
    };
    handleFetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenderImageSrc = (imagesArray, numberInOrder) => {
    const imagesArrayAlt =
      imagesArray && imagesArray.length
        ? imagesArray
        : [
            {
              src: "https://panel.armancnc.com/wp-content/smush-webp/woocommerce-placeholder-600x600.png.webp",
            },
            {
              src: "https://panel.armancnc.com/wp-content/smush-webp/woocommerce-placeholder-600x600.png.webp",
            },
          ];

    const imageData = imagesArrayAlt[numberInOrder - 1];

    return (
      imageData && (
        <img src={imageData.src} alt="catalogue" className="catalogue-img" />
      )
    );
  };

  const handleRenderMeta = (productMeta, productName) => {
    const metaToReturn = productMeta.filter((productMeta) =>
      allowedMeta.includes(productMeta.key)
    );
    const mapKeys = Object.keys(metaLabelMap);

    const metaWithSortIndex = metaToReturn
      .map((meta) => {
        const sortIndex = mapKeys.findIndex((mapKey) => mapKey === meta.key);
        return { ...meta, sortIndex };
      })
      .sort((a, b) => b - a);
    const sortedMeta = _.sortBy(metaWithSortIndex, "sortIndex");

    const allMeta = sortedMeta
      .map(({ key, value }) => {
        const valueToCheck = Array.isArray(value) ? value[0] : value;

        const valueToReturn =
          valueToCheck &&
          valueToCheck.trim() !== "" &&
          valueToCheck.toLowerCase() !== "array"
            ? value
            : undefined;

        return valueToReturn
          ? {
              label: metaLabelMap[key] || key,
              value: valueToReturn,
            }
          : undefined;
      })
      .filter((meta) => !!meta);

    return [{ label: "نام دستگاه", value: productName }, ...allMeta];
  };

  return (
    <>
      <button
        onClick={() => {
          window.print();
        }}
        className="export-btn"
      >
        دانلود خروجی PDF
      </button>
      <Loader visible={isLoaderVisible} />
      <div ref={printContentRef} className="print-pages">
        {products.map((product) => (
          <div className="page-container-catalogue">
            <div className="page-header-text">
              <div>
                <h1 id="category-title">{product.name}</h1>
                {product.sku ? (
                  <h6 id="sku">شناسه آرمان: {product.sku}</h6>
                ) : (
                  <h6 id="sku">شناسه پیشنهاد: {product.id}</h6>
                )}
              </div>
            </div>
            <div className="columns-container">
              <div className="column first">
                <div className="large-image-container">
                  {handleRenderImageSrc(product.images, 1)}
                </div>
                <div className="info-table-container">
                  <div className="info-table">
                    <table>
                      {handleRenderMeta(product.meta_data, product.name).map(
                        ({ label, value }) => {
                          return (
                            <tr>
                              <td
                                id="value"
                                className={
                                  label === "توضیحات تکمیلی"
                                    ? "small-value"
                                    : ""
                                }
                              >
                                {value}
                              </td>
                              <td id="label">{label}</td>
                            </tr>
                          );
                        }
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className="column second">
                <div className="large-image-container">
                  {handleRenderImageSrc(product.images, 2)}
                </div>
                <div className="medium-images-container">
                  <div className="medium-image-container">
                    {handleRenderImageSrc(product.images, 3)}
                  </div>
                  <div className="medium-image-container">
                    {handleRenderImageSrc(product.images, 4)}
                  </div>
                  <div className="medium-image-container">
                    {handleRenderImageSrc(product.images, 5)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
