import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./inventory.style.scss";
import moment from "moment-jalaali";
import { Loader } from "../../lib/ui/components";
import _ from "lodash";
import { axios } from "../../lib/constants/api";
// import { useReactToPrint } from "react-to-print";

const metaLabelMap = {
  machine_type: "تیپ",
  post_title: "نام دستگاه",
  company: "شرکت سازنده",
  model: "مدل",
  year_of_manufacturing: "سال ساخت",
  made_in: "کشور سازنده",
  controller: "کنترلر",
  controller_model: "مدل کنترلر",
  chip_conveyor: "سفاله کش",
  turret_type: "نوع تارت",
  number_of_turret_tools: "تعداد ابزار تارت",
  turret_engine_type: "نوع موتور تارت",
  tailstock: "مرغک",
  lunette: "لینت",
  chuck_size: "قطر سه نظام (میلی متر)",
  machining_diameter: "قطر ماشین کاری (میلی متر)",
  turning_diameter: "قطر قابل گردش (میلی متر)",
  machining_length: "طول قابل تراشکاری (میلی متر)",
  have_c_axis: "محور c",
  have_y_axis: "محور y",
  spindle_bore_type: "گلو اسپیندل",
  spindle_bore_diameter: "قطر گلویی (میلی متر)",
  spindle_type: "نوع اسپیندل",
  max_spindle_speed: "حداکثر دور اسپیندل (RPM)",
  table_dimensions: "ابعاد میز (میلی متر)",
  number_of_axes: "تعداد محور",
  tool_changer_type: "نوع تول چنجر",
  number_of_tools: "تعداد ابزار",
  tool_std: "استاندارد ابزار",
  tool_size: "اندازه ابزار",
  axes_mechanism: "مکانیزم محوره",
  "x-axis-travel": "کورس محور X (میلی متر)",
  "y-axis-travel": "کورس محور Y (میلی متر)",
  "z-axis-travel": "کورس محور Z (میلی متر)",
  "w-axis-travel": "کورس محور W (میلی متر)",
  supplementary_details: "توضیحات تکمیلی",
};

export const InventoryPrintComponent = () => {
  const chunkSize = 5;

  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const [isPrintToggled, setIsPrintToggled] = useState(false);

  const printContentRef = useRef(null);
  const todayDate = moment().format("jYYYY/jMM/jDD");

  const handleGetCategoryInfo = (product) => {
    if (!product) return undefined;
    const matchedCategoryId = product.categories.find(({ id }) => {
      console.log(product.categories, categoryId);
      return String(id) === categoryId;
    });
    if (matchedCategoryId && matchedCategoryId.slug === "lathe")
      matchedCategoryId.slug = "cnc-lathe";

    return matchedCategoryId;
  };

  const altCategoryDocument = handleGetCategoryInfo(products[0]);

  const handleGetProductInfoKeys = (product) => {
    let sortCriteria = product.name;

    const isMachineEquals = (machineName) =>
      product.categories.some(({ name }) => name.includes(machineName));

    const commonMeta = ["made_in", "controller", "year_of_manufacturing"];
    const specialMeta = isMachineEquals("تراش")
      ? ["chuck_size", "machining_diameter", "max_spindle_speed"]
      : ["x-axis-travel", "y-axis-travel", "z-axis-travel"];

    const productInfoKeys = product.meta_data.filter(({ key }) =>
      [...specialMeta, ...commonMeta].includes(key)
    );

    productInfoKeys.forEach(({ key, value }) => {
      if (
        isMachineEquals("تراش")
          ? key.includes("chuck_size")
          : key.includes("x-axis-travel")
      ) {
        sortCriteria = isNaN(Number(value)) ? value : Number(value);
      }
    });

    return { productInfoKeys, sortCriteria };
  };

  const prepareProductsToSet = (productsToPrepare) => {
    const preparedProducts = productsToPrepare.map((ptp) => {
      return {
        ...ptp,
        ...handleGetProductInfoKeys(ptp),
      };
    });

    return _.sortBy(preparedProducts, "sortCriteria");
  };

  // const handlePrintWindow = useReactToPrint({
  //   content: () => printContentRef.current,
  //   documentTitle: `استعلام ${
  //     (altCategoryDocument && altCategoryDocument.name) || "Arman cnc inventory"
  //   } - تاریخ ${todayDate}`,
  //   onAfterPrint: () => {
  //     const altCategoryDocument = handleGetCategoryInfo(products[0]);
  //     if (altCategoryDocument && altCategoryDocument.slug) {
  //       window.open(
  //         `https://armancnc.com/inventory/${altCategoryDocument.slug}`,
  //         "_self"
  //       );
  //     } else window.close();
  //   },
  // });

  const handlePrint = () => {
    setIsPrintToggled(true);
    // window.print();
    // handlePrintWindow();
  };

  useEffect(() => {
    const handleFetchProducts = async () => {
      const productsRequest = await axios.get(
        `/products/category/${categoryId}`
      );
      const fetchedProducts = productsRequest.data;

      setProducts(prepareProductsToSet(fetchedProducts));
    };
    handleFetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    const isSinglePrintPage = chunkSize >= products.length;

    if (isSinglePrintPage) {
      document.body.style.backgroundSize = "cover";
    } else {
      document.body.style.backgroundSize = "contain";
    }
  }, [products]);

  useEffect(() => {
    if (!!products.length && !isPrintToggled)
      setTimeout(() => {
        setIsLoadingVisible(false);
        setTimeout(() => {
          handlePrint();
        }, 500);
      }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, isPrintToggled]);

  const handleChunkProducts = () => {
    const splitedProducts = [];
    for (let i = 0; i < products.length; i += chunkSize) {
      splitedProducts.push(products.slice(i, i + chunkSize));
    }

    return splitedProducts;
  };

  const handleRenderTable = (productRows) => {
    return (
      <div className="page-container">
        <table className="inventory-products-table">
          <thead>
            <tr>
              <th>#</th>
              <th>تصویر</th>
              <th>عنوان</th>
              <th>جزئیات</th>
            </tr>
          </thead>
          <tbody>
            {productRows.map((product) => (
              <tr>
                <td id="product-id">{product.sku}</td>
                <td id="product-img">
                  <img src={product.images[0].src} alt="product-img" />
                </td>
                <td id="product-title">
                  <a href={product.permalink} target="_blank" rel="noreferrer">
                    {product.name}
                  </a>
                </td>
                <td id="product-info">
                  {product.productInfoKeys.map(({ value, key }) => {
                    const content = value || "";
                    return (
                      <>
                        <span>{`${metaLabelMap[key]}: ${content}`}</span>
                        <br />
                      </>
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <Loader visible={isLoadingVisible} />
      <div ref={printContentRef} className="print-pages">
        <div className="page-header-text">
          <h4 id="category-title">
            استعلام موجودی دستگاه{" "}
            {(altCategoryDocument && altCategoryDocument.name) ||
              (products.length && "تراش CNC") ||
              "{دسته بندی}"}
          </h4>
          <h5 id="date">تاریخ استعلام: {todayDate}</h5>
        </div>
        {!!products.length ? (
          handleChunkProducts().map((chunkedProducts) =>
            handleRenderTable(chunkedProducts)
          )
        ) : (
          <div className="page-container"></div>
        )}
      </div>
    </>
  );
};
