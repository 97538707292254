import React from "react";
import { Triangle } from "react-loader-spinner";

export const Loader = ({ visible }) => {
  return visible ? (
    <div className="main-loader-container">
      <Triangle
        visible={visible || false}
        height="80"
        width="80"
        color="#ac2727"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClass="main-loader"
      />
      <div className="loader-helper">
        <p>در حال آماده سازی فایل، لطفا منتظر بمانید...</p>
      </div>
    </div>
  ) : (
    <></>
  );
};
